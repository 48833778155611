import React, { useState, useEffect, useRef } from "react";
import { object, number } from "prop-types";
import { Subheadline } from "@quintype/arrow";

import StoryElements from "../story-elements";
import CardImage from "../../atoms/cardimage";
import AuthorAndTimeStamp from "../story-header/author-and-timestamp";

import Resizer from "../story-header/resizer";
import FollowUs from "../story-header/follow-us";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import SocialMediaShare from "../../molecules/social-media-share";

import StorySideBar from "../story-sidebar";

import "./exclusive.m.css";
import { get } from "lodash";
import CommentsCount from "../../atoms/comments-count";

const DHExclusiveStoryTemplate = ({ story, index, aspectRatio, imageType }) => {
  const customKicker = get(story, ["metadata", "story-attributes", "customkicker"], "");
  const [showSharingOptions, setShowSharingOptions] = useState(false);
  const shareRef = useRef(null);
  const [isMobile, setMobile] = useState(false);
  const [timestampShareWidth, setTimestampShareWidth] = useState("auto");
  const timestampShareRef = useRef(null);

  useEffect(() => {
    if (window && window.innerWidth < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const authorWrapper = document.querySelector("#exclusive-author-wrapper");
      const mobileHeader = document.querySelector("#mob-header");
      const desktopHeader = document.querySelector("#desk-header");

      const authorWrapperBottom = authorWrapper?.getBoundingClientRect().bottom;
      const mobileHeaderBottom = mobileHeader?.getBoundingClientRect().bottom;
      const desktopHeaderBottom = desktopHeader?.getBoundingClientRect().bottom;

      if (typeof window !== "undefined") {
        if (isMobile) {
          mobileHeaderBottom >= authorWrapperBottom + 41 ? setIsSticky(true) : setIsSticky(false);
        } else {
          desktopHeaderBottom >= authorWrapperBottom ? setIsSticky(true) : setIsSticky(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMobile, setIsSticky]);

  const sharingHandler = () => {
    setShowSharingOptions(!showSharingOptions);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showSharingOptions && !shareRef.current.contains(event.target)) {
        setShowSharingOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showSharingOptions]);

  useEffect(() => {
    if (timestampShareRef.current) {
      setTimestampShareWidth(`${timestampShareRef.current.offsetWidth}px`);
    }
  }, [isSticky]);

  const CommentAndShareBtn = (
    <div styleName="comment-share-wrapper">
      <CommentsCount story={story} showCommentText={false} renderPortal={true} />
      <div styleName="share" ref={shareRef}>
        <button onClick={sharingHandler}>
          <SvgIconHandler type="storyshare" height="36px" width="36px" />
        </button>
        <div styleName="share-options">{showSharingOptions && <SocialMediaShare story={story} />}</div>
      </div>
    </div>
  );

  return (
    <>
      <div styleName="wrapper">
        <div>
          <div styleName="header-content">
            <CardImage
              story={story}
              aspectRatio={aspectRatio}
              imageType={imageType}
              renderedWidth={720}
              widths={[700]}
              showVideoIcon={true}
            />

            <div styleName="headline-wrapper" id="exclusive-author-wrapper">
              {customKicker.length > 0 ? <div styleName="custom-kicker">{customKicker}</div> : null}
              <h1>{story.headline}</h1>
              <Subheadline lineClamp={10} story={story} clazzName="sub-headline" />
            </div>
            <div styleName={` ${isSticky && !isMobile ? "alternate-wrapper" : ""}`}></div>
            <div
              styleName="exclusive-author-wrapper"
              className={`exclusive-sticky-div ${isSticky && !isMobile ? "exclusive-sticky" : ""}`}
              ref={timestampShareRef}
              style={{ width: timestampShareWidth }}
            >
              {isMobile ? (
                <>
                  <AuthorAndTimeStamp story={story} isPVExclusive={true} />
                  <div styleName={` ${isSticky && isMobile ? "alternate-wrapper" : ""}`}></div>
                  <div
                    styleName="followus-wrapper"
                    id="followus-id"
                    className={`exclusive-sticky-div ${isSticky & isMobile ? "exclusive-sticky" : ""}`}
                    ref={timestampShareRef}
                    style={{ width: timestampShareWidth }}
                  >
                    <FollowUs />
                    {CommentAndShareBtn}
                  </div>
                </>
              ) : (
                <div styleName="followus-wrapper">
                  <FollowUs />
                  <div styleName="long-line"></div>
                  <AuthorAndTimeStamp story={story} isPVExclusive={true} />
                  {CommentAndShareBtn}
                </div>
              )}
              {/* <Resizer story={story} /> */}
            </div>
          </div>
          <div styleName="sub-content">
            <div styleName="story-content">
              <div styleName="content">
                <StoryElements story={story} cards={story.cards} index={index} />
              </div>
            </div>
            <div className="hide-mobile side-story-section">
              <StorySideBar index={index} story={story} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DHExclusiveStoryTemplate.propTypes = {
  story: object,
  index: number
};

DHExclusiveStoryTemplate.defaultProps = {
  aspectRatio: [16, 9],
  imageType: "image16x9"
};

const ExclusiveStory = (props) => {
  return <DHExclusiveStoryTemplate story={props.story} index={props.index} />;
};

ExclusiveStory.propTypes = {
  story: object,
  index: number
};

export default ExclusiveStory;
