import React, { useState, useEffect } from "react";
import { array, string } from "prop-types";
import { throttle } from "lodash";

import CardImage from "../../atoms/cardimage";

import "./story-sticky-bar.m.css";

const StoriesStickyBar = ({ aspectRatio, imageType, quickNavigationStories, baseStory, isMobile }) => {
  const [showStoriesNav, setStoriesNav] = useState(false);
  const getScroll = throttle(() => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 700) setStoriesNav(true);
      if (window.scrollY < 600) setStoriesNav(false);
    }
  }, 1000);

  useEffect(() => {
    !isMobile && window.addEventListener("scroll", getScroll);
    return () => !isMobile && window.removeEventListener("scroll", getScroll);
  }, []);

  return (
    <div style={{ display: showStoriesNav ? "flex" : "none" }}>
      {!isMobile ? (
        baseStory["story-template"] === "text" || baseStory["story-template"] === "syndicated" ? (
          quickNavigationStories.length > 0 ? (
            <div styleName="sticky-stories-wrapper">
              {quickNavigationStories.slice(0, 5).map((story, index) => (
                <a styleName="sticky-story-card" key={index} href={story?.url}>
                  <CardImage
                    story={story}
                    aspectRatio={aspectRatio}
                    imageType={imageType}
                    renderedWidth={720}
                    widths={[720]}
                    showVideoIcon={true}
                  />
                  <div styleName="sticky-story-card-headline">{story.headline}</div>
                </a>
              ))}
            </div>
          ) : null
        ) : null
      ) : null}
    </div>
  );
};

StoriesStickyBar.defaultProps = {
  aspectRatio: [16, 9],
  imageType: "image16x9"
};

StoriesStickyBar.propTypes = {
  aspectRatio: array,
  imageType: string
};

export default StoriesStickyBar;
