import React, { useEffect, useState } from "react";
import { number, object, shape, any } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { WithPreview } from "@quintype/components";

import { setLatestSectionStoriesAction, setStoryTemplate } from "../../store/actions";
import TaboolaScript from "../../ads/taboola-ad/load-taboola-ad";
import { getCollection } from "../../helper/api";

import Bredcrum from "../../story-templates/story-header/bredcrums";
import TextStory from "../../story-templates/text";
import VideoStory from "../../story-templates/video";
import PodCastStory from "../../story-templates/podcast";
import PhotoStory from "../../story-templates/photo";
import ListcleStory from "../../story-templates/listcle";
import LiveBlogStory from "../../story-templates/live-blog";
import ExclusiveStory from "../../story-templates/exclusive";
import CartoonStory from "../../story-templates/cartoon";
import PanchangaStory from "../../story-templates/panchanga";
import HoroscopeStory from "../../story-templates/horoscope";
import EmbedStory from "../../story-templates/embed-story";
import ImmersiveStory from "../../story-templates/immersive";
import StoriesStickyBar from "../../molecules/stories-sticky-bar";

import { getSectionSlug2 } from "../../helper/utils";
import { AdContainer } from "../../ads";
import Separator from "../../atoms/separator";
import "./story.m.css";
import useAdType from "../../helper/useAdType";

const pickTemplate = {
  text: TextStory,
  video: VideoStory,
  photo: PhotoStory,
  podcast: PodCastStory,
  embed: EmbedStory,
  cartoon: CartoonStory,
  listicle: ListcleStory,
  panchanga: PanchangaStory,
  horoscope: HoroscopeStory,
  "live-blog": LiveBlogStory,
  "fact-check": TextStory,
  "dh-exclusive": ExclusiveStory,
  "movie-review": TextStory,
  "gadget-review": TextStory,
  "book-review": TextStory,
  "sponsored-story": TextStory,
  "text-new": TextStory,
  "news-briefs": TextStory,
  "video-playlist": PodCastStory
};

function StoryPageBase({ index, story, initialPath }) {
  const isWebView = initialPath.includes("app=true");
  const dispatch = useDispatch();
  const [quickNavigationStories, setQuickNavigationStories] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setMobile] = useState(true);

  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const config = get(qtState, ["config"], {});
  const sectionSlug = (index) => getSectionSlug2(config, story, index);
  const modifiedPath = initialPath.split("?")[0];

  const storyReducer = useSelector((state) => get(state, ["storyReducer"], []));
  const makeSecondaryApiCalls = storyReducer.makeSecondaryApiCalls;
  const delay = storyReducer.delay;
  const adType = useAdType();

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }

    if (isWebView) {
      document.getElementById("container").style.minHeight = "unset";
      document.getElementsByClassName("page-wrapper")[0].style.minHeight = "unset";
    }

    dispatch(setStoryTemplate(story["story-template"]));
  }, []);

  useEffect(() => {
    if (story["story-template"] === "text" || story["story-template"] === "syndicated") {
      if (makeSecondaryApiCalls) {
        setTimeout(() => fetchQuickNavigationStories(), delay);
        setTimeout(() => setIsVisible(true), delay + 500);
      }
    }
  }, [makeSecondaryApiCalls]);

  useEffect(() => {
    document.oncopy = addLinkToCopy;
  }, []);

  const addLinkToCopy = () => {
    const selection = window.getSelection();
    const pagelink = `
      <br /><br /> Read more at: <a href='${document.location.href}'>
      ${document.location.href}</a><br />`;
    let copytext;
    if (selection.toString().length > 250) {
      copytext = selection.toString().substring(0, 250) + "..." + pagelink;
    } else {
      copytext = selection.toString().substring(0, 250) + pagelink;
    }

    const newdiv = document.createElement("div");
    newdiv.style.cssText = "position: absolute; left: -99999px;";
    document.body.appendChild(newdiv);
    newdiv.innerHTML = copytext;
    selection.selectAllChildren(newdiv);
    setTimeout(() => document.body.removeChild(newdiv), 0);
  };

  const fetchQuickNavigationStories = async () => {
    try {
      if (quickNavigationStories.length === 0) {
        const [section1Slug, section2Slug, section3Slug] = await Promise.all([
          sectionSlug(1),
          sectionSlug(2),
          sectionSlug(3)
        ]);
        let stories;
        if (section1Slug) {
          stories = await getCollection(section1Slug, 0, 5, story.id).catch(() => {
            if (section2Slug)
              return getCollection(section2Slug, 0, 5, story.id).catch(() => {
                if (section3Slug) return getCollection(section3Slug, 0, 5, story.id);
                throw new Error("Failed to fetch fetchQuickNavigationStories.");
              });
            throw new Error("Failed to fetch fetchQuickNavigationStories.");
          });
        }
        stories && dispatch(setLatestSectionStoriesAction(stories));
        stories && setQuickNavigationStories(stories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Component = pickTemplate[story["story-template"]] || TextStory;

  let taboolaCategoryType;

  switch (story["story-template"]) {
    case "video":
      taboolaCategoryType = "video";
      break;
    case "video-playlist":
      taboolaCategoryType = "video";
      break;
    case "podcast":
      taboolaCategoryType = "video";
      break;
    case "photo":
      taboolaCategoryType = "photo";
      break;
    default:
      taboolaCategoryType = "article";
  }

  return (
    <>
      {story["story-template"] === "immersive" ||
      (story["story-template"] !== "live-blog" &&
        story["story-template"] !== "video" &&
        story["story-template"] !== "listicle" &&
        story["story-template"] !== "horoscope" &&
        isWebView) ? (
        <ImmersiveStory story={story} index={index} />
      ) : (story["story-template"] === "live-blog" ||
          story["story-template"] === "video" ||
          story["story-template"] === "listicle" ||
          story["story-template"] === "horoscope") &&
        isWebView ? (
        <Component story={story} index={index} isWebView={isWebView} />
      ) : (
        <div styleName="story-wrapper" className="container">
          <TaboolaScript type={taboolaCategoryType} />
          <div className="hide-desktop">
            <Separator />
          </div>
          {isMobile ? (
            <div styleName="ad-wrapper" className="hide-desktop">
              <AdContainer
                AdClassName="standard-336x280"
                id={adType === "izooto" ? "div-gpt-ad-1711971869798-0" : "div-gpt-ad-1680157824963-0"}
                mobile={adType === "izooto" ? "DH_MWeb_AT_Top_ym" : "DH_MWeb_AT_Top"}
                isExternalAdPath={adType === "izooto" ? true : false}
              />
            </div>
          ) : null}
          <div styleName="bredcrum-wrapper">
            <Bredcrum initialPath={modifiedPath} story={story} type="story-page" />
          </div>
          <div styleName="story-component-wrapper">
            <Component story={story} index={index} />
          </div>
          {isVisible && (
            <StoriesStickyBar baseStory={story} isMobile={isMobile} quickNavigationStories={quickNavigationStories} />
          )}
        </div>
      )}
    </>
  );
}

StoryPageBase.propTypes = {
  index: number,
  story: object,
  otherProp: any
};
export function StoryPage(props) {
  return (
    <div>
      <StoryPageBase {...props.data} initialPath={props.currentPath} />
    </div>
  );
}

StoryPage.propTypes = {
  data: shape({
    story: object
  })
};

export const StoryPagePreview = WithPreview(StoryPage, (data, story) =>
  Object.assign({}, data, {
    story,
    relatedStories: Array(5).fill(story)
  })
);
