import React, { useState, useEffect } from "react";
import { object, number, bool } from "prop-types";
import { format } from "date-fns";
import get from "lodash/get";
import throttle from "lodash/throttle";

import ReadMoreButton from "../../atoms/read-more";
import StorySeparator from "../../atoms/story-separator";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";

import SubscribeCard from "../../molecules/subscribe-card";
import SocialMediaShare from "../../molecules/social-media-share";

import StoryHeader from "../story-header";
import Tags from "../story-elements/tags";
import StorySideBar from "../story-sidebar";
import MobileBottomWidgets from "../mobile-bottom-widgets";
import { StoryCard } from "../story-elements";
import PublishedAt from "../story-elements/published-at";

import CommentsCount from "../../atoms/comments-count";

import TaboolaAd from "../../ads/taboola-ad";

import KeyEvents from "./key-events";

import "./live-blog.m.css";

const TimeStamp = ({ card, story, index, ToggleHandler, toggleSharing, isWebView }) => {
  const time = format(card["card-added-at"], "kk:mm");
  const timeFormatted = new Date(card["card-added-at"]).toLocaleString("en-US", {
    timeZone: "Asia/Calcutta",
    hour: "numeric",
    minute: "numeric",
    hourCycle: "h23"
  });
  const date = format(card["card-added-at"], "dd MMM yyyy");

  return (
    <div styleName="event-date">
      <div styleName="event-data">
        <span>{timeFormatted}</span>
        <span>{date}</span>
      </div>
      {!isWebView && (
        <div styleName="share-icon-wrapper">
          <button styleName="share" onClick={() => ToggleHandler(index)}>
            <SvgIconHandler type="share" height="20px" width="20px" />
          </button>
          <div styleName="share-options">{toggleSharing === index && <SocialMediaShare story={story} />}</div>
        </div>
      )}
    </div>
  );
};

TimeStamp.propTypes = {
  story: object,
  card: object,
  isWebView: bool
};

const LiveBlogStoryTemplate = ({ story, index, isWebView = false }) => {
  const [showReadMore, setShowReadMore] = useState(true);
  const [showCards, setShowCards] = useState(5);
  const [toggleSharing, setToggleSharing] = useState(false);
  const cardsLength = story.cards.length - 1;
  const closeLiveBlog = story?.["first-published-at"] < 1688185800000;
  const [isMobile, setMobile] = useState(false);
  const isClosed = get(story, ["metadata", "is-closed"], false);

  let storyCards = [];
  let length = cardsLength;

  while (length >= 0) {
    storyCards[cardsLength - length] = story.cards[length];
    length--;
  }

  storyCards = isClosed ? (story["owner-name"].toLowerCase() !== "migrator" ? storyCards : story.cards) : story.cards;

  const [renderTaboolaAd, setRenderTaboolaAd] = useState(false);

  useEffect(() => {
    const getScroll = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > 500) setRenderTaboolaAd(true);
      }
    };
    window.addEventListener("scroll", throttle(getScroll, 500));
    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  useEffect(() => {
    console.log(showCards);
    if (showCards >= 10) {
      window.history.replaceState(null, null, `#${Math.floor((showCards - 5) / 5)}`);
    }
  }, [showCards]);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const handleReadMore = () => {
    setShowCards(() => showCards + 5);
    window.dataLayer.push({
      event: "liveBlog"
    });
  };

  const ToggleHandler = (idx) => {
    setToggleSharing(toggleSharing === idx ? undefined : idx);
  };

  return isWebView ? (
    <div styleName="story-wrapper">
      {story["owner-name"].toLowerCase() !== "migrator" && <KeyEvents story={story} handleReadMore={handleReadMore} />}
      <div styleName="story-cards">
        {storyCards.slice(0, showCards).map((card, index) => {
          const showTimeStamp = get(card, ["metadata", "attributes", "hidetimestamp"], "");
          const cardsLength = story.cards.length;
          const isLastCard = cardsLength < showCards ? index == cardsLength - 1 : index == showCards - 1;
          return (
            <div key={index}>
              <div styleName={`event-item event-item-brd ${isLastCard ? "event-item-brdr-btm" : ""}`} key={index}>
                {["yes", "Yes", "true", "True", "t", "T"].includes(showTimeStamp[0]) ? null : (
                  <TimeStamp
                    card={card}
                    story={story}
                    index={index}
                    ToggleHandler={ToggleHandler}
                    toggleSharing={toggleSharing}
                    isWebView={isWebView}
                  />
                )}
                <div styleName="event-content" id={"card" + card.id}>
                  <StoryCard card={card} story={story} key={index} showAlsoReadOnTop={false} />
                  {!isLastCard && <div styleName="line"></div>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div styleName="add-margin-for-load-more">
        {cardsLength >= showCards && showReadMore ? (
          <ReadMoreButton text="Load More" onClick={() => handleReadMore()} className="buttonWrapper" />
        ) : null}
      </div>
    </div>
  ) : (
    <>
      {index > 0 && <StorySeparator />}
      <div styleName="story-wrapper">
        <div id={`story-content-${story.id}`} className="story-section">
          <div>
            <StoryHeader story={story} isLiveBlog={true} closeLiveBlog={closeLiveBlog} />
            {story["owner-name"].toLowerCase() !== "migrator" && (
              <KeyEvents story={story} handleReadMore={handleReadMore} />
            )}
            <div styleName="story-cards">
              {storyCards.slice(0, showCards).map((card, index) => {
                const showTimeStamp = get(card, ["metadata", "attributes", "hidetimestamp"], "");
                const cardsLength = story.cards.length;
                const isLastCard = cardsLength < showCards ? index == cardsLength - 1 : index == showCards - 1;
                return (
                  <div key={index}>
                    <div styleName={`event-item event-item-brd ${isLastCard ? "event-item-brdr-btm" : ""}`} key={index}>
                      {["yes", "Yes", "true", "True", "t", "T"].includes(showTimeStamp[0]) ? null : (
                        <TimeStamp
                          card={card}
                          story={story}
                          index={index}
                          ToggleHandler={ToggleHandler}
                          toggleSharing={toggleSharing}
                        />
                      )}
                      <div styleName="event-content" id={"card" + card.id}>
                        <StoryCard card={card} story={story} key={index} showAlsoReadOnTop={false} />
                        {!isLastCard && <div styleName="line"></div>}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {cardsLength >= showCards && showReadMore ? (
              <ReadMoreButton text="Load More" onClick={() => handleReadMore()} className="buttonWrapper" />
            ) : null}
            {/* <CommentsCount story={story} /> */}
            <PublishedAt story={story} />
            <Tags story={story} />
            <SubscribeCard story={story} />
            {isMobile ? (
              <div className="side-story-section hide-desktop">
                <MobileBottomWidgets index={index} story={story} />
              </div>
            ) : null}
            {renderTaboolaAd ? (
              <TaboolaAd
                container={`'taboola-below-article-${story.id}`}
                placement="Below Article Thumbnails"
                mode="thumbnails-b"
              />
            ) : null}
          </div>
        </div>
        <div className="side-story-section hide-mobile">
          <StorySideBar index={index} story={story} />
        </div>
      </div>
    </>
  );
};

LiveBlogStoryTemplate.propTypes = {
  story: object,
  index: number,
  card: object,
  isWebView: bool
};

const LiveBlogStory = (props) => {
  return <LiveBlogStoryTemplate story={props.story} index={props.index} isWebView={props.isWebView} />;
};

LiveBlogStory.propTypes = {
  story: object,
  index: number,
  isWebView: bool
};

export default LiveBlogStory;
