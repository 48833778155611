import React from "react";
import { format } from "date-fns";
import get from "lodash/get";

import { StoryCard } from "../../story-elements";

import "./key-events.m.css";

const getEvents = (cards = []) => {
  return cards.map((card) => {
    const titleElement = card["story-elements"].find(({ type }) => type === "title");

    if (titleElement) {
      return {
        ...titleElement,
        "card-id": card.id,
        "card-added-at": card["card-added-at"]
      };
    }
  });
};

const TimeStamp = ({ card }) => {
  const time = format(card["card-added-at"], "kk:mm");
  const date = format(card["card-added-at"], "dd MMM yyyy");

  return (
    <div styleName="event-date">
      <span>{time}</span>
      <span>{date}</span>
    </div>
  );
};
const handleScroll = async (element, handleReadMore) => {
  if (typeof window !== "undefined") {
    await handleReadMore();
    var element = document.getElementById("card" + element.id);
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - 100;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
};
const KeyEvents = ({ story, handleReadMore }) => {
  const events = getEvents(story.cards);

  if (!events.length) return null;
  const withKeyEvents = story.cards.filter(
    (card) =>
      card.metadata?.attributes &&
      card.metadata?.attributes["key-event"] &&
      card.metadata?.attributes["key-event"] === true
  );
  return (
    <div styleName="wrapper">
      {withKeyEvents.length && story["owner-name"] !== "Migrator" ? (
        <>
          <div styleName="title">Highlights</div>
          <div styleName="events-timeline">
            {withKeyEvents?.map((card, idx) => {
              const showTimeStamp = get(card, ["metadata", "attributes", "hidetimestamp"], "");
              return (
                <div styleName="event-item" key={idx}>
                  {["yes", "Yes", "true", "True", "t", "T"].includes(showTimeStamp[0]) ? null : (
                    <TimeStamp card={card} story={story} />
                  )}
                  <div onClick={() => handleScroll(card, handleReadMore)} styleName="event-container">
                    <StoryCard
                      card={{
                        ...card,
                        "story-elements": card["story-elements"].filter((el) => el.type === "title")
                      }}
                      story={story}
                      key={idx}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default KeyEvents;
