import React from "react";

import { object, number } from "prop-types";

import StoryElements from "../story-elements";

const ImmersiveStory = ({ story, index }) => {
  return <StoryElements story={story} cards={story.cards} index={index} isImmersiveStory={true} />;
};

ImmersiveStory.propTypes = {
  story: object,
  index: number
};

export default ImmersiveStory;
