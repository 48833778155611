import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "@quintype/components";
import { array, string, object } from "prop-types";
import CardImage from "../../../atoms/cardimage";
import RightArrowReadMoreIcon from "../../../atoms/icons/right-arrow-read-more";
import get from "lodash/get";

import "./section-stories.m.css";

const SectionStories = ({ aspectRatio, imageType, story }) => {
  const storyReducer = useSelector((state) => get(state, ["storyReducer"], []));
  const makeSecondaryApiCalls = storyReducer.makeSecondaryApiCalls;
  const delay = 3000;

  const [isVisible, setIsVisible] = useState(false);
  const [sectionStories, setSectionStories] = useState([]);
  const [sectionSlug, setSectionSlug] = useState("");
  const latestSectionStories = useSelector((state) => get(state, ["storyReducer", "latestSectionStories"], []));
  const sectionsSlug = story.slug.split("/").slice(0, -1);

  useEffect(() => {
    setSectionSlug(sectionsSlug.join("/"));

    if (makeSecondaryApiCalls) {
      latestSectionStories && setSectionStories(latestSectionStories);
      setTimeout(() => setIsVisible(true), delay);
    }
  }, [makeSecondaryApiCalls, latestSectionStories]);

  if (sectionStories.length < 4) return null;

  return (
    <div styleName="section-stories-wrapper">
      <div styleName="header-wrapper">
        <div styleName="title-wrapper">
          <div styleName="title-tile"></div>
          <span styleName="title">What Others Are Reading</span>
        </div>
        <Link styleName="see-more-stories" href={`/${sectionSlug}`}>
          <span styleName="see-more" className="hide-mobile">
            See More
          </span>
          <RightArrowReadMoreIcon />
        </Link>
      </div>
      {isVisible ? (
        <div styleName="stories-wrapper">
          {sectionStories.length > 0
            ? sectionStories.slice(0, 4).map((story, index) => (
                <a styleName="story-card" key={index} href={story.url}>
                  <div styleName="story-headline">{story.headline}</div>
                  <CardImage
                    story={story}
                    aspectRatio={aspectRatio}
                    imageType={imageType}
                    renderedWidth={720}
                    widths={[720]}
                    showVideoIcon
                  />
                </a>
              ))
            : null}
        </div>
      ) : null}
    </div>
  );
};

SectionStories.defaultProps = {
  aspectRatio: [16, 9],
  imageType: "image16x9"
};

SectionStories.propTypes = {
  aspectRatio: array,
  imageType: string,
  story: object
};

export default SectionStories;
